<template>
    <div class="patent-navigation-page">
        <div class="banner-wrap">
            <div class="banner">
                <div class="banner-box">
                    <div class="banner-text">
                        <div class="title animate__animated animate__fadeInLeftBig">
                            专利导航服务
                        </div>
                        <div class="sub-title animate__animated animate__fadeInRightBig">
                            <p>
                                专利导航是围绕科技企业的技术节点，通过专利检索、权利分析、布局指导、同行技术跟踪、风险监测等手段，保护企业技术领先性，完善企业技术攻防，提升科技企业专利管理、管理、运营的能力，从而指导和促进企业的发展。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="effect-main animate__animated animate__fadeIn">
            <div class="content">
                <div class="card-main">
                    <div class="card-item" :style="`background-image: url('${item.bgImage}')`"
                        v-for="(item, index) in effectList" :key="index">
                        <div class="card-item-title">
                            {{ item.title }}
                        </div>
                        <div class="card-item-subtitle">
                            {{ item.subTitle }}
                        </div>
                        <div class="card-item-introduce">
                            <p v-html="item.introduce"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <keep-alive>
            <trial id="consultingService" class="trial" title="咨询专利导航服务" sceneCode="4" />
        </keep-alive>
    </div>
</template>
<script>
import trial from '@/components/trial.vue'
export default {
    name: 'patentNavigation',
    components: { trial },
    data() {
        return {
            effectList: [
                {
                    title: '导研发',
                    subTitle: '增效率降费用',
                    introduce: '从海量专利文献提取有效研发资料</br>构建技术专利数据库</br>规划技术研发路线路线图',
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/25/leadRD.png'
                }, {
                    title: '导布局',
                    subTitle: '添专利攻防',
                    introduce: '产品未动，专利先行</br>境内+境外形成有效的专利组合</br>交叉许可等多方式削弱专利壁垒',
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/25/guideLayout.png',
                }, {
                    title: '导维权',
                    subTitle: '明辨权利侵权风险',
                    introduce: '梳理自身及同行专利情况</br>有效分析各自权利保护范围</br>及时发现产品侵权或被侵权',
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/25/rightOfDefense.png',
                }
            ]
        }
    },
    created() {

    },
    methods: {

    },
}
</script>
<style lang="scss" scoped>
.patent-navigation-page {
    .banner-wrap {
        background-color: #e0edfb;

        .banner {
            width: 100%;
            min-width: $container-width;
            max-width: Translate(1920px);
            height: Translate(500px);
            background: url("https://images.zzt.com.cn/gnW/2024/04/25/patent-navigation-banner.png") no-repeat;
            background-position: center center;
            background-size: cover;
            margin: 0 auto;

            &-box {
                width: $container-width;
                margin: 0 auto;
                position: relative;
                height: 100%;
            }

            &-text {
                text-align: left;

                .title {
                    position: absolute;
                    top: Translate(170px);
                    left: 0;
                    font-size: Translate(40px);
                    font-weight: 500;
                }

                .sub-title {
                    position: absolute;
                    top: Translate(240px);
                    left: 0;
                    font-size: Translate(16px);
                    color: #666;
                    width: Translate(774px);

                    p {
                        height: Translate(30px);
                        line-height: Translate(30px);
                    }
                }
            }
        }
    }

    .effect-main {
        width: 100%;
        height: Translate(520px);
        box-sizing: border-box;
        background: #FFFFFF;

        .card-main {
            display: flex;
            justify-content: space-between;

            .card-item {
                width: Translate(384px);
                height: Translate(400px);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                .card-item-title {
                    margin-top: Translate(194px);
                    font-size: Translate(24px);
                    color: #4088EB;
                    line-height: Translate(34px);
                    font-weight: 500;
                }

                .card-item-subtitle {
                    margin-top: Translate(16px);
                    font-size: Translate(18px);
                    color: #333333;
                    text-align: center;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::after {
                        content: '';
                        width: Translate(80px);
                        height: Translate(1px);
                        background: #999999;
                        margin-left: Translate(10px);
                    }

                    &::before {
                        content: '';
                        width: Translate(80px);
                        height: Translate(1px);
                        background: #999999;
                        margin-right: Translate(10px);

                    }
                }

                .card-item-introduce {
                    margin-top: Translate(5px);

                    p {
                        font-size: Translate(14px);
                        color: #666666;
                        text-align: center;
                        line-height: Translate(24px);
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

section {
    padding-top: Translate(60px);

    .content {
        width: $container-width;
        margin: 0 auto;


        .img-container {
            display: flex;
            justify-content: space-between;

            img {
                margin-left: Translate(20px);
            }
        }
    }

    .title {
        font-size: Translate(36px);
        color: #333333;
        font-weight: 500;
        margin: 0 auto Translate(46px);
        min-width: $container-width;
    }
}
</style>